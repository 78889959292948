import React, { useCallback, useState } from 'react'
import Button from 'react-bootstrap/Button'
import './StationsFiltering.scss'
import { Col, Container, Modal, Row, Stack } from 'react-bootstrap'
import { CheckLg, Sliders, Trash } from 'react-bootstrap-icons'
import { isMobile } from 'react-device-detect'
import { useAppDispatch } from '../../hooks'
import FuelTypeFilter from './filters/FuelTypeFilter'
import RadiusFilter from './filters/RadiusFilter'
import { filteringReset, refreshStationsToDisplay } from '../stationsSlice'

type FilteringContentProps = {
	onApply: () => void,
}

const FilteringContent = ({ onApply }: FilteringContentProps): JSX.Element => {
	const dispatch = useAppDispatch()

	const resetFilters = useCallback(() => {
		dispatch(filteringReset())
		dispatch(refreshStationsToDisplay())
	}, [])

	return (
		<Stack className='stations-filtering' gap={4}>
			<FuelTypeFilter />

			<RadiusFilter />

			{/* Bottom buttons */}
			<Row>
				<Col>
					<Button variant='outline-danger' onClick={resetFilters}>
						<Trash />
						<span>Resetuj</span>
					</Button>
				</Col>
				<Col>
					<Button onClick={onApply}>
						<CheckLg />
						<span>Zastosuj</span>
					</Button>
				</Col>
			</Row>
		</Stack>
	)
}

const StationsFiltering = () => {
	const [show, setShow] = useState<boolean>(false)
	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)

	return (
		<React.Fragment>
			{isMobile
				? (
					<Button variant='secondary' onClick={handleShow}>
						<span>Filtry</span>
						<Sliders size='20px' />
					</Button>
				)
				: (
					<Container className='p-4 pt-3'>
						<Button onClick={handleShow}>
							<Sliders /><span>Filtry</span>
						</Button>
					</Container>
				)}

			<Modal
				className={isMobile ? 'mobile' : ''}
				show={show}
				onHide={handleClose}
				backdrop='static'
			>
				<Modal.Header closeButton={true}>
					Filtruj
				</Modal.Header>
				<Modal.Body>
					<FilteringContent onApply={handleClose} />
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default StationsFiltering
