import React from 'react'
import './SelectedStationModal.scss'
import Modal from 'react-bootstrap/Modal'
import StationDetails from '../station-details/StationDetails'
import useStationSelection from './hooks/useStationSelection'
import { isMobile } from 'react-device-detect'

const StationSelectedModal = () => {
	const { unselectStation, selectedStation } = useStationSelection()

	if (!selectedStation)
		return (<React.Fragment />)

	return (
		<Modal
			className={`station-selected-modal ${isMobile ? ' mobile' : ''}`}
			show={!!selectedStation}
			onHide={unselectStation}
		>
			<Modal.Header closeButton={true} />
			<Modal.Body>
				<StationDetails
					station={selectedStation}
					showOnMapVisible={false}
				/>
			</Modal.Body>
		</Modal>
	)
}

export default StationSelectedModal
