import React, { useCallback, useState } from 'react'
import { StationPrices } from '../../models'
import { Button, Modal } from 'react-bootstrap'
import { Bell } from 'react-bootstrap-icons'
import { isMobile } from 'react-device-detect'
import ProposePricesForm from './ProposePricesForm'
import LoginButton from '../../../../common/auth/LoginButton'
import { useAuth } from '../../../../common/auth/AuthContainer'

type Props = {
	stationPrices: StationPrices,
	isTooFar: boolean,
}

const Confirm = () => {
	return (
		<p>Dziękujemy za zgłoszenie.</p>
	)
}

const NotLoggedInProposePrices = (): JSX.Element => {
	return (
		<React.Fragment>
			<p>Aby zaktualizować ceny musisz się zalogować</p>
			<LoginButton />
		</React.Fragment>
	)
}

const TooFarToProposePrices = (): JSX.Element => {
	return (
		<React.Fragment>
			<p>Musisz znajdować się blisko stacji, aby móc aktualizować ceny.</p>
		</React.Fragment>
	)
}

const LoggedInProposePrices = (props: {onSubmit: () => void} & Props): JSX.Element => {
	const [showConfirm, setShowConfirm] = useState(false)

	const handleOnSubmit = () => {
		setShowConfirm(true)

		setTimeout(() => {
			props.onSubmit()

			setTimeout(() => {
				setShowConfirm(false)
			}, 200)
		}, 1000)
	}

	return (
		<React.Fragment>

			{showConfirm
				? <Confirm />
				: (
					<ProposePricesForm
						onSubmit={handleOnSubmit}
						currentStationPrices={props.stationPrices} />
				)}
		</React.Fragment>
	)
}

const ProposePricesButton = (props: Props): JSX.Element => {
	const [showModal, setShowModal] = useState(false)
	const { isLoggedIn } = useAuth()

	const handleShowModal = useCallback(async () => {
		setShowModal(true)
	}, [isLoggedIn])

	const handleHideModal = () => {
		setShowModal(false)
	}

	return (
		<React.Fragment>
			<Button variant="primary" onClick={handleShowModal}>
				<Bell />
				<span>Zgłoś cenę</span>
			</Button>

			<Modal
				className={isMobile ? 'mobile' : ''}
				show={showModal}
				aria-labelledby="contained-modal-title-vcenter"
				onHide={handleHideModal}
				fullscreen={isMobile ? true : ''}
				backdrop='static'
			>
				<Modal.Header closeButton={true}>
					Zgłoś ceny
				</Modal.Header>
				<Modal.Body>
					{props.isTooFar
						? <TooFarToProposePrices />
						: isLoggedIn
							? <LoggedInProposePrices onSubmit={handleHideModal} {...props} />
							: <NotLoggedInProposePrices />
					}
				</Modal.Body>
			</Modal>
		</React.Fragment>
	)
}

export default ProposePricesButton
