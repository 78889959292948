import { useMemo } from 'react'
import config from '../../../config'
import { getDiffDaysFromNow, getDistanceFromLatLonInKm } from '../../utils/mathUtilities'
import { Station } from '../models'
import { useAppSelector } from '../../hooks'

const useStationProperties = (station: Station) => {
	const { myCoords } = useAppSelector((state) => state.geolocation)
	const areYouAround = useMemo(() => {
		if (config.AdminMode)
			return false

		if (!myCoords)
			return false

		return getDistanceFromLatLonInKm(myCoords, station.coords) < 0.2
	}, [myCoords])

	const lastUpdateText = useMemo(() => {
		if (station.prices.prices.length === 0)
			return 'Brak informacji o cenach'

		if (!station.prices?.lastUpdate)
			return ''

		const diffDays = getDiffDaysFromNow(station.prices.lastUpdate)

		return `Ostatnia aktualizacja ${diffDays} dni temu`
	}, [station.prices.lastUpdate, station.prices.prices.length])

	return {
		areYouAround,
		lastUpdateText,
	}
}

export default useStationProperties
