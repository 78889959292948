import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { FuelType, Station } from './models'
import stationsApi from './stationsApi'
import { Coords } from '../geolocation/coords'
import { getCoordsStage, getDistanceFromLatLonInKm } from '../utils/mathUtilities'
import { RootState } from '../store'
import { Filters, SortBy } from './filtering/models'
import filterAndSortStations from './filtering/getFilteredStations'
import fillDistances from './utils/fillDistances'

export type StationsState = {
	stations: Station[],
	stationsToDisplay: Station[],
	isLoading: boolean,
	lastSearchCoords: Coords | undefined,
	searchedStages: Coords[],
	filters: Filters,
	sortBy: SortBy,
}

const initialState: StationsState = {
	stations: [],
	stationsToDisplay: [],
	isLoading: false,
	lastSearchCoords: undefined,
	searchedStages: [],
	filters: {
		radius: 15,
		fuelType: undefined,
	},
	sortBy: 'distance',
}

export const stationsSlice = createSlice({
	name: 'stations',
	initialState,
	reducers: {
		searchCoordsChanged: (state, action: PayloadAction<Coords>) => ({
			...state,
			lastSearchCoords: action.payload,
		}),
		stationsLoadingStarted: (state) => ({
			...state,
			isLoading: true,
		}),
		stationsLoaded: (state, action: PayloadAction<Station[]>) => {
			const loadedStations = action.payload

			if (!loadedStations.length) {
				return {
					...state,
					isLoading: false,
				}
			}

			const concatObjects = [...state.stations, ...loadedStations]
			const distinctObjects = new Map(concatObjects.map((x) => [x.id, x])).values()
			const stationsToPut = Array.from(distinctObjects)

			const newStageLoaded = loadedStations[0].stageCoords
			return {
				...state,
				isLoading: false,
				stations: stationsToPut,
				searchedStages: [
					...state.searchedStages,
					{
						latitude: newStageLoaded.latitude,
						longitude: newStageLoaded.longitude,
					},
				],
			}
		},
		refreshStationsToDisplay: (state, action: PayloadAction<Coords | undefined>) => {
			if (!state.lastSearchCoords)
				return

			const stationsToDisplay = filterAndSortStations(
				state.stations,
				state.lastSearchCoords,
				state.filters,
				state.sortBy
			)

			const myCoords = action.payload
			if (myCoords)
				fillDistances(stationsToDisplay, myCoords)

			return {
				...state,
				stationsToDisplay,
			}
		},
		filterRadiusChanged: (state, action: PayloadAction<number>) => ({
			...state,
			filters: {
				...state.filters,
				radius: action.payload,
			},
		}),
		filterFuelTypeChanged: (state, action: PayloadAction<FuelType | undefined>) => ({
			...state,
			filters: {
				...state.filters,
				fuelType: action.payload,
			},
		}),
		sortByChanged: (state, action: PayloadAction<SortBy>) => ({
			...state,
			sortBy: action.payload,
		}),
		filteringReset: (state) => ({
			...state,
			filters: { ...initialState.filters },
			sortBy: initialState.sortBy,
		}),
	},
})

export const { filterFuelTypeChanged, filterRadiusChanged, filteringReset, sortByChanged, refreshStationsToDisplay } = stationsSlice.actions

const wasStageSearchedBefore = (state: StationsState, coords: Coords) => {
	const stageSearchCoords = getCoordsStage(coords)
	const { searchedStages } = state

	return searchedStages.find((x) => x.latitude === stageSearchCoords.latitude && x.longitude === stageSearchCoords.longitude)
}

const canChangeSearchCoords = (state: StationsState, newSearchCoords: Coords) =>
	!state.lastSearchCoords || getDistanceFromLatLonInKm(state.lastSearchCoords, newSearchCoords) > 5

export const updateStationsInCoords = createAsyncThunk(
	'stations/loadStations',
	async (coords: Coords, { getState, dispatch }) => {
		const { stations: stationsState } = getState() as RootState
		if (!canChangeSearchCoords(stationsState, coords))
			return

		dispatch(stationsSlice.actions.stationsLoadingStarted())
		dispatch(stationsSlice.actions.searchCoordsChanged(coords))

		let newStationsSearched: Station[] = []
		if (!wasStageSearchedBefore(stationsState, coords)) {
			const stageSearchCoords = getCoordsStage(coords)
			newStationsSearched = await stationsApi.getStations(stageSearchCoords)
		}

		dispatch(stationsSlice.actions.stationsLoaded(newStationsSearched))
		dispatch(stationsSlice.actions.refreshStationsToDisplay())
	}
)

export default stationsSlice
