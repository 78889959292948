import React from 'react'
import { Button } from 'react-bootstrap'
import { useAuth } from './AuthContainer'

const LoginButton = () => {
	const auth = useAuth()

	return (
		<Button onClick={() => auth.ensureLoggedIn()}>Zaloguj</Button>
	)
}

export default LoginButton
