import React, { useMemo } from 'react'
import { Coords } from '../../geolocation/coords'
import getDistance from './getDistance'
import { CursorFill } from 'react-bootstrap-icons'

type Props = {
	areYouAround: boolean,
	stationCoords: Coords,
	myCoords?: Coords,
}

const Distance = ({ stationCoords, myCoords, areYouAround }: Props): JSX.Element => {
	const distanceText = useMemo(() => getDistance(stationCoords, myCoords), [myCoords])

	if (!distanceText)
		return (<React.Fragment />)

	if (areYouAround)
		return (<div className='distance is-close-enough'><CursorFill /> <span>Jesteś w pobliżu</span></div>)

	return (
		<div className='distance'><CursorFill /> {distanceText}</div>
	)
}

export default Distance
