import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import useStationSelection from './useStationSelection'

const useSelectStationFromRouteEffect = () => {
	const { stationId } = useParams()
	const { selectStationWithZoom } = useStationSelection()

	useEffect(() => {
		if (stationId)
			selectStationWithZoom(stationId)
	}, [])
}

export default useSelectStationFromRouteEffect
