import { MsalProvider, useMsal, useMsalAuthentication } from '@azure/msal-react'
import React, { createContext, useCallback, useContext } from 'react'
import msalInstance from './msal'
import { loginRequest } from './msalConfig'
import { InteractionType } from '@azure/msal-browser'

type AuthContextType = {
	ensureLoggedIn: () => Promise<void>,
	isLoggedIn: boolean,
}

export const AuthContext = createContext<AuthContextType>({ } as AuthContextType)

export const useAuth = () => useContext(AuthContext)

const AuthProvider = (props: {children: any}) => {
	const { accounts } = useMsal()
	const { login, error } = useMsalAuthentication(InteractionType.Silent, {
		...loginRequest,
		loginHint: accounts[0]?.username,
	})

	const ensureLoggedIn = useCallback(async () => {
		if (error)
			await login(InteractionType.Redirect, loginRequest)
	}, [error])

	return (
		<AuthContext.Provider value={{
			ensureLoggedIn,
			isLoggedIn: !!accounts[0],
		}}>
			{props.children}
		</AuthContext.Provider>
	)
}

const AuthContainer = (props: {children: any}) => {
	return (
		<MsalProvider instance={msalInstance}>
			<AuthProvider>
				{props.children}
			</AuthProvider>
		</MsalProvider>
	)
}

export default AuthContainer
