import React, { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { followMyLocation, unfollowMyLocation } from './stationsMapSlice'
import { Crosshair } from 'react-bootstrap-icons'
import { Button } from 'react-bootstrap'
import useStationSelection from '../selected-station/hooks/useStationSelection'

const FollowMyLocation = () => {
	const dispatch = useAppDispatch()
	const { myCoords, geolocationPermissionGranted } = useAppSelector((state) => state.geolocation)
	const { isFollowingMyLocation } = useAppSelector((state) => state.stationsMap)
	const { unselectStation, isAnyStationSelected } = useStationSelection()

	useEffect(() => {
		if (isAnyStationSelected)
			dispatch(unfollowMyLocation())
	}, [isAnyStationSelected])

	const onClick = useCallback((e: any) => {
		e.stopPropagation()

		if (myCoords) {
			unselectStation()
			dispatch(followMyLocation())
		}
	}, [myCoords])

	if (!geolocationPermissionGranted)
		return (<React.Fragment />)

	return (
		<Button
			className={`my-location-btn ${!isFollowingMyLocation && 'unfollow'}`}
			onClick={onClick}
			style={{ textDecoration: 'line-through' }}
		>
			{geolocationPermissionGranted
				? <Crosshair />
				: (
					<React.Fragment>
						<Crosshair color='gray' />
						<div className='tilted-line' />
					</React.Fragment>
				)
			}
		</Button>
	)
}

export default FollowMyLocation
