import React from 'react'
import { FuelType } from '../models'

const typeToIconMap: {
	[fuelType: string]: {
		displayName: string,
		fontColor: string,
		backgroundColor: string,
	},
} = {
	Diesel: {
		displayName: 'ON',
		backgroundColor: 'black',
		fontColor: 'white',
	},
	Gas: {
		displayName: 'Pb',
		backgroundColor: 'green',
		fontColor: 'white',
	},
	Lpg: {
		displayName: 'LPG',
		backgroundColor: 'white',
		fontColor: 'red',
	},
	Electric: {
		displayName: 'EV',
		backgroundColor: 'blue',
		fontColor: 'white',
	},
	Hydrogen: {
		displayName: 'H',
		backgroundColor: 'blue',
		fontColor: 'white',
	},
}

const FuelIcon = ({ fuelType }: {fuelType: FuelType}): JSX.Element => {
	const map = typeToIconMap[fuelType]
	if (!map)
		return (<React.Fragment />)

	return (
		<div className='icon'
			style={{
				backgroundColor: map.backgroundColor,
				color: map.fontColor,
				width: '50px',
				textAlign: 'center',
				borderRadius: '5px',
			}}
		>
			{map.displayName}
		</div>)
}

export default FuelIcon
