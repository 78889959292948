import { createSlice } from '@reduxjs/toolkit'

type State = {
	isFollowingMyLocation: boolean,
}

const initialState: State = { isFollowingMyLocation: true }

const stationsMapSlice = createSlice({
	name: 'stationsMap',
	initialState,
	reducers: {
		followMyLocation: (state) => ({
			...state,
			isFollowingMyLocation: true,
		}),
		unfollowMyLocation: (state) => ({
			...state,
			isFollowingMyLocation: false,
		}),
	},
})

export const { followMyLocation, unfollowMyLocation } = stationsMapSlice.actions

export default stationsMapSlice
