import React from 'react'
import { isMobile } from 'react-device-detect'
import ShareButton from './ShareButton'
import CopyButton from './CopyButton'
import { ShareStationProps } from './types'

const ShareStationButton = (props: ShareStationProps): JSX.Element => {
	return (
		<div className='share-station'>
			{isMobile
				? <ShareButton {...props} />
				: <CopyButton {...props} />}
		</div>)
}

export default ShareStationButton
