import React from 'react'
import { isMobile } from 'react-device-detect'
import StationSelectedModal from './selected-station/SelectedStationModal'
import StationList from './stations-list/StationsList'
import StationsMap from './stations-map/StationsMap'
import StationsFiltering from './filtering/StationsFiltering'
import { Stack } from 'react-bootstrap'
import useSelectStationFromRouteEffect from './selected-station/hooks/useSelectStationFromRouteEffect'

const StationsContainer = () => {
	useSelectStationFromRouteEffect()

	const mapToDisplay = (
		<div className="main-content">
			<StationsMap />
		</div>
	)

	if (isMobile) {
		return (
			<React.Fragment>
				{mapToDisplay}
				<StationSelectedModal />
			</React.Fragment>
		)
	}

	return (
		<React.Fragment>
			<Stack className="left-sidebar">
				<StationsFiltering />
				<StationList />
			</Stack>

			{mapToDisplay}
		</React.Fragment>
	)
}

export default StationsContainer
