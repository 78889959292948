import React, { useCallback, useMemo } from 'react'
import './StationsList.scss'
import StationListItem from './StationListItem'
import { Station } from '../models'
import { Stack } from 'react-bootstrap'
import { useAppSelector } from '../../hooks'
import useStationSelection from '../selected-station/hooks/useStationSelection'

type Props = {
	onShowOnMap?: () => void,
}

const StationList = (props: Props) => {
	const { selectedStationId } = useAppSelector((state) => state.selectedStation)
	const { stationsToDisplay } = useAppSelector((state) => state.stations)
	const { selectStationWithZoom } = useStationSelection()

	const onSelect = useCallback((stationId: string) => {
		if (selectedStationId !== stationId)
			selectStationWithZoom(stationId)
	}, [selectedStationId, selectStationWithZoom])

	const stationListItems = useMemo(() => stationsToDisplay.map(
		(x: Station) => (
			<div key={x.id}>
				<StationListItem
					station={x}
					onShowOnMap={props.onShowOnMap}
					onSelect={onSelect}
				/>
			</div>
		)
	), [stationsToDisplay])

	return (
		<Stack gap={2} className='station-list p-3 pt-0'>
			{stationListItems}
		</Stack>
	)
}

export default StationList
