import React from 'react'
import { StationPrices } from '../models'
import { Row } from 'react-bootstrap'
import FuelIcon from './FuelIcon'
import { useAppSelector } from '../../hooks'

type Props = {
	prices: StationPrices,
}
const FuelPrices = ({ prices }: Props): JSX.Element => {
	const { fuelType } = useAppSelector((state) => state.stations.filters)

	return (
		<Row className='oils'>
			{prices.prices
				.filter((x) => !!fuelType ? x.fuel.type === fuelType : true)
				.map((fuelPrice) => (
					<div key={fuelPrice.fuel.name} className='oil'>
						<FuelIcon fuelType={fuelPrice.fuel.type} />
						<span><i>{fuelPrice.fuel.name}</i>: <b>{fuelPrice.price.toFixed(2)} {fuelPrice.priceCurrency}</b></span>
					</div>
				))}
		</Row>
	)
}

export default FuelPrices
