import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import stationsApi from '../stationsApi'
import { updateStationsInCoords } from '../stationsSlice'
import { RootState } from '../../store'

type SelectedStationState = {
	selectedStationId: string | undefined,
	zoomToStation: boolean | undefined,
}

const initialState: SelectedStationState = {
	selectedStationId: undefined,
	zoomToStation: undefined,
}

const selectedStationSlice = createSlice({
	name: 'selectedStation',
	initialState,
	reducers: {
		stationSelected: (state, action: PayloadAction<string>) => ({
			...state,
			selectedStationId: action.payload,
			zoomToStation: undefined,
		}),
		zoomToStation: (state) => ({
			...state,
			zoomToStation: true,
		}),
		stationUnselected: (state) => ({
			...state,
			selectedStationId: undefined,
			zoomToStation: undefined,
		}),
	},
})

export const { stationSelected, zoomToStation, stationUnselected } = selectedStationSlice.actions

export const loadSelectedStation = createAsyncThunk(
	'stationSelected/loadSelectedStation',
	async (stationId: string, { getState, dispatch }) => {
		// First check if station is loaded to store
		const { stations } = (getState() as RootState).stations
		const stationToSelect = stations.find((x) => x.id === stationId)
		if (!stationToSelect) {
			// load station to store if not found
			const coords = await stationsApi.getStationCoords(stationId)
			if (!coords)
				return

			await dispatch(updateStationsInCoords(coords))
		}

		dispatch(stationSelected(stationId))
	}
)

export default selectedStationSlice
