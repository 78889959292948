import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { loadSelectedStation, stationUnselected, zoomToStation } from '../selectedStationSlice'

const useStationSelection = () => {
	const navigate = useNavigate()
	const dispatch = useAppDispatch()
	const { selectedStationId } = useAppSelector((state) => state.selectedStation)
	const { stations } = useAppSelector((state) => state.stations)

	const selectedStation = useMemo(() => stations.find((x) => x.id === selectedStationId), [stations, selectedStationId])

	const isStationSelected = useCallback((stationId: string) => selectedStationId === stationId, [selectedStationId])

	const isAnyStationSelected = !!selectedStationId

	const unselectStation = useCallback(() => {
		if (!isAnyStationSelected)
			return

		navigate(-1)
		dispatch(stationUnselected())
	}, [isAnyStationSelected])

	const selectStation = useCallback(async (stationId: string) => {
		navigate('/' + stationId, { replace: isAnyStationSelected })
		await dispatch(loadSelectedStation(stationId))
	}, [isAnyStationSelected])

	const selectStationWithZoom = useCallback(async (stationId: string) => {
		await selectStation(stationId)
		dispatch(zoomToStation())
	}, [selectStation])

	return {
		selectStation,
		selectStationWithZoom,
		unselectStation,
		isStationSelected,
		isAnyStationSelected,
		selectedStation,
	}
}

export default useStationSelection
