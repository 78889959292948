import { Coords } from '../../geolocation/coords'
import { displayDistance, getDistanceFromLatLonInKm } from '../../utils/mathUtilities'

const getDistance = (stationCoords: Coords, myCoords: Coords | undefined): string => {
	if (!myCoords)
		return ''

	const distanceInKm = getDistanceFromLatLonInKm(stationCoords, myCoords)
	return displayDistance(distanceInKm)
}

export default getDistance
