import { Coords } from '../../geolocation/coords'
import { getDistanceFromLatLonInKm } from '../../utils/mathUtilities'
import { FuelPrice, Station } from '../models'
import { Filters, SortBy } from './models'

const avgPrice = (prices: FuelPrice[]): number => {
	if (prices.length === 0)
		return 9e+10 // enough high value to be put at the end of the list

	return prices
		.map((x) => x.price)
		.reduce((prev, next) => prev + next) / prices.length
}

const filterAndSortStations = (
	stations: Station[],
	searchCoords: Coords,
	filters: Filters,
	sortBy: SortBy
): Station[] => {
	const { radius, fuelType } = filters
	let filteredStations = [...stations]

	// filter by radius
	filteredStations = filteredStations.filter((x) => getDistanceFromLatLonInKm(x.coords, searchCoords) <= radius)

	// filter by gas type
	if (!!fuelType)
		filteredStations = filteredStations.filter((x) => !!x.prices.prices.find((price) => price.fuel.type === fuelType))

	// sort by distance
	if (sortBy === 'distance')
		filteredStations.sort((a, b) => a.distanceInKm - b.distanceInKm)
	// sort by price
	else if (sortBy === 'price')
		filteredStations.sort((a, b) => avgPrice(a.prices.prices)- avgPrice(b.prices.prices))

	return filteredStations
}

export default filterAndSortStations
