import React, { useEffect } from 'react'
import { GeoFill } from 'react-bootstrap-icons'
import { useAppSelector } from '../../hooks'

const markerId = 'current-search-point-marker'

let currentSearchPointMarkerEl: HTMLElement | null

const rectOverlapBoundary = 20

export const isOverlapping = (markerRect: DOMRect | undefined): boolean => {
	currentSearchPointMarkerEl ??= document.getElementById(markerId)
	if (!currentSearchPointMarkerEl || !markerRect)
		return false

	const searchPointMarkerRect = currentSearchPointMarkerEl.getBoundingClientRect()

	return (
		searchPointMarkerRect.left < markerRect.right + rectOverlapBoundary
        && searchPointMarkerRect.right > markerRect.left - rectOverlapBoundary
        && searchPointMarkerRect.top < markerRect.bottom + rectOverlapBoundary
        && searchPointMarkerRect.bottom > markerRect.top - rectOverlapBoundary
	)
}

const CurrentSearchPointMarker = () => {
	const { isFollowingMyLocation } = useAppSelector((state) => state.stationsMap)

	useEffect(() => {
		if (isFollowingMyLocation)
			currentSearchPointMarkerEl = null

	}, [isFollowingMyLocation])

	return (
		<GeoFill
			id={markerId}
			className='leaflet-control'
			style={{
				position: 'absolute',
				left: '50%',
				top: '50%',
				transform: 'translate(-50%, -50%)',
				display: isFollowingMyLocation ? 'none' : '',
			}} size="2em" color='blue' />
	)
}

export default CurrentSearchPointMarker
