import React, { useCallback, useMemo } from 'react'
import { Button, Col, Container, Dropdown, Row } from 'react-bootstrap'
import { FuelType } from '../../models'
import { Trash } from 'react-bootstrap-icons'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { filterFuelTypeChanged, refreshStationsToDisplay } from '../../stationsSlice'

const fuelsDict: {[fuelType: string]: string[]} = {
	Gas: ['Benzyna', 'success'],
	Diesel: ['Diesel', 'dark'],
	Lpg: ['LPG', 'danger'],
	Electric: ['EV', 'primary'],
	Hydrogen: ['Wodór', 'primary'],
}

type Props = {
	fuelType: FuelType,
	text: string,
	btnVariant: string,
}
const FuelTypeButton = ({ fuelType }: Props): JSX.Element => {
	const dispatch = useAppDispatch()
	const { fuelType: currentFilterByFuelType } = useAppSelector((state) => state.stations.filters)

	const isSelected = useMemo(() => currentFilterByFuelType === fuelType, [currentFilterByFuelType, fuelType])

	const setFilteringByFuelType = useCallback(() => {
		dispatch(filterFuelTypeChanged(fuelType))
		dispatch(refreshStationsToDisplay())
	}, [])

	return (
		<Dropdown.Item
			onClick={setFilteringByFuelType}
			active={isSelected}
		>
			{fuelsDict[fuelType][0]}
		</Dropdown.Item>
	)
}

const FuelTypeFilter = (): JSX.Element => {
	const dispatch = useAppDispatch()
	const { fuelType } = useAppSelector((state) => state.stations.filters)

	const onReset = useCallback(() => dispatch(filterFuelTypeChanged(undefined)), [])

	return (
		<Container className='p-0'>
			<Row>
				{/* Dropdown fuel type */}
				<Col>
					<Dropdown>
						<Dropdown.Toggle variant={!!fuelType ? fuelsDict[fuelType][1] : 'outline-primary'}>
							{!!fuelType ? fuelsDict[fuelType][0] : 'Wybierz rodzaj paliwa'}
						</Dropdown.Toggle>

						<Dropdown.Menu>
							<Dropdown.ItemText>
								<b>Wybierz rodzaj paliwa</b>
							</Dropdown.ItemText>

							<FuelTypeButton
								btnVariant='success'
								text='Benzyna'
								fuelType='Gas'
							/>
							<FuelTypeButton
								btnVariant='dark'
								text='Diesel'
								fuelType='Diesel'
							/>
							<FuelTypeButton
								btnVariant='danger'
								text='LPG'
								fuelType='Lpg'
							/>
							<FuelTypeButton
								btnVariant='primary'
								text='EV'
								fuelType='Electric'
							/>
							<FuelTypeButton
								btnVariant='primary'
								text='Wodór'
								fuelType='Hydrogen'
							/>
						</Dropdown.Menu>
					</Dropdown>
				</Col>

				{/* Reset fuel type filter */}
				{!!fuelType && (
					<Col xs={3}>
						<Button
							variant='outline-danger'
							onClick={onReset}
						>
							<Trash size='24px' />
						</Button>
					</Col>
				)}
			</Row>
		</Container>
	)
}

export default FuelTypeFilter
