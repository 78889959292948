import { Coords } from '../../geolocation/coords'
import { getDistanceFromLatLonInKm } from '../../utils/mathUtilities'
import { Station } from '../models'

const fillDistances = (stations: Station[], myCoords: Coords): Station[] => {
	return stations.map((station) => ({
		...station,
		distanceInKm: getDistanceFromLatLonInKm(station.coords, myCoords),
	}))
}

export default fillDistances
