import React from 'react'
import { Marker } from 'react-leaflet'
import L from 'leaflet'
import { useAppSelector } from '../../hooks'

const MyCoordsMarker = () => {
	const { myCoords } = useAppSelector((state) => state.geolocation)

	return myCoords ? (
		<Marker
			icon={L.icon({
				iconUrl: 'mycoords.svg',
				iconSize: L.point(20, 20),
			})}
			position={{
				lat: myCoords.latitude,
				lng: myCoords.longitude,
			}} />
	) : (
		<React.Fragment />
	)
}

export default MyCoordsMarker
