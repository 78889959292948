import { combineSlices, configureStore } from '@reduxjs/toolkit'
import stationsSlice from '../stations/stationsSlice'
import stationsMapSlice from '../stations/stations-map/stationsMapSlice'
import selectedStationSlice from '../stations/selected-station/selectedStationSlice'
import geolocationSlice from '../geolocation/geolocationSlice'

const reducer = combineSlices(
	stationsSlice,
	selectedStationSlice,
	stationsMapSlice,
	geolocationSlice,
)

const store = configureStore({ reducer })

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof reducer>

export default store
