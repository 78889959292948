import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Coords } from './coords'

type GeolocationState = {
	myCoords?: Coords,
	isGeolocationLoaded: boolean,
	geolocationPermissionGranted: boolean | undefined,
}

const initialState: GeolocationState = {
	myCoords: undefined,
	isGeolocationLoaded: false,
	geolocationPermissionGranted: undefined,
}

const geolocationSlice = createSlice({
	name: 'geolocation',
	initialState,
	reducers: {
		myCoordsChanged: (state, action: PayloadAction<Coords>) => ({
			...state,
			myCoords: action.payload,
		}),
		geolocationPermissionDenied: (state) => ({
			...state,
			geolocationPermissionGranted: false,
			isGeolocationLoaded: true,
			myCoords: undefined,
		}),
		geolocationLoadingFailed: (state) => ({
			...state,
			isGeolocationLoaded: true,
			myCoords: undefined,
		}),
		geolocationSucceed: (state) => ({
			...state,
			geolocationPermissionGranted: true,
			isGeolocationLoaded: true,
		}),
	},
})

export const {
	geolocationSucceed,
	geolocationPermissionDenied,
	myCoordsChanged,
	geolocationLoadingFailed,
} = geolocationSlice.actions

export default geolocationSlice
