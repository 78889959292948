import React from 'react'
import './ProposePricesForm.scss'
import { useFormik } from 'formik'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { StationPrices } from '../../models'
import { useBrandFuels } from '../../hooks/useBrandFuels'
import stationsApi from '../../stationsApi'
import FuelFormInput from './FuelPriceInput'

const clearZeroValues = (values: any) => {
	Object.keys(values).forEach((key) => {
		if (values[key] <= 0)
			delete values[key]
	})
}

type Props = {
	currentStationPrices: StationPrices,
	onSubmit: () => void,
}

const ProposePricesForm = ({ currentStationPrices, onSubmit }: Props) => {
	const brandFuels = useBrandFuels(currentStationPrices.brand)

	const formik = useFormik({
		initialValues: { },
		onSubmit: (values: {[fuelId: string]: number}) => {
			clearZeroValues(values)

			onSubmit()
			stationsApi.proposePrices(
				currentStationPrices.stationId,
				Object.keys(values)
					.map((fuelId) => {
						return {
							fuelId: fuelId,
							price: values[fuelId],
						}
					})
			)
			// eslint-disable-next-line no-empty-function
				.catch(() => {})
		},
	})

	return (
		<Form
			className='prices-form'
			onSubmit={formik.handleSubmit}>
			{brandFuels.map((fuel) => (
				<FuelFormInput
					key={fuel.id}
					formik={formik}
					fuel={fuel}
					fuelPrice={currentStationPrices.prices.find((x) => x.fuelId === fuel.id)} />
			))}

			<Button type="submit" variant="primary">
				Wyślij
			</Button>
		</Form>
	)
}

export default ProposePricesForm
