import React, { createRef, useEffect, useMemo } from 'react'
import './StationsList.scss'
import { Station } from '../models'
import StationDetails from '../station-details/StationDetails'
import { isMobile } from 'react-device-detect'
import { useAppSelector } from '../../hooks'
import useStationSelection from '../selected-station/hooks/useStationSelection'

const scrollIntoListItem = (itemRefJsx: React.RefObject<HTMLDivElement>) => {
	itemRefJsx?.current?.scrollIntoView({
		behavior: 'instant',
		block: 'center',
	})
}

type Props = {
	station: Station,
	onShowOnMap?: () => void,
	onSelect: (stationId: string) => void,
}

const StationListItem = ({ onSelect, station, onShowOnMap }: Props) => {
	const stationsLength = useAppSelector((state) => state.stations.stationsToDisplay.length)
	const { sortBy } = useAppSelector((state) => state.stations)
	const { isStationSelected } = useStationSelection()

	const ref = createRef<HTMLDivElement>()

	const isSelected = isStationSelected(station.id)

	useEffect(() => {
		if (isStationSelected(station.id))
			scrollIntoListItem(ref)
	}, [isStationSelected, stationsLength]) // stationsLength is used to scroll to the selected station when the list is updated

	useEffect(() => {
		scrollIntoListItem(ref)
	}, [sortBy])

	const classNameIsSelected = useMemo(() => `station-list-item ${isSelected ? 'selected' : ''}`, [isSelected])

	if (isMobile) {
		return (
			<div
				id={station.id}
				className={`${classNameIsSelected} mobile`}
				ref={ref}
			>
				<StationDetails
					station={station}
					onShowOnMap={onShowOnMap}
					showOnMapVisible={true}
					withPricesProposal={false}
				/>
			</div>
		)
	}

	return (
		<div
			id={station.id}
			className={`${classNameIsSelected} desktop`}
			onClick={() => onSelect(station.id)}
			ref={ref}
		>
			{!isMobile && <i className='click-hint'>Kliknij aby pokazać na mapie</i>}
			<StationDetails
				station={station}
				onShowOnMap={onShowOnMap}
				showOnMapVisible={true}
				withPricesProposal={true}
			/>
		</div>
	)
}

export default StationListItem
