import React, { useEffect, useRef, useState } from 'react'
import './StationsMap.scss'
import { warsawPkinCoords } from '../../geolocation/consts'
import { updateStationsInCoords } from '../stationsSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Coords } from '../../geolocation/coords'
import { MapContainer, TileLayer } from 'react-leaflet'
import { Map } from 'leaflet'
import StationMapStationsCluster from './StationsMapCluster'
import MyCoordsMarker from './MyCoordsMarker'
import { unfollowMyLocation } from './stationsMapSlice'
import FollowMyLocation from './FollowMyLocationButton'
import StationsFiltering from '../filtering/StationsFiltering'
import { isMobile } from 'react-device-detect'
import CurrentSearchPointMarker from './CurrentSearchPointMarker'
import useStationSelection from '../selected-station/hooks/useStationSelection'

const goTo = (map: Map | null, coords: Coords) => {
	map?.panTo({
		lat: coords.latitude,
		lng: coords.longitude,
	})
}

const StationsMap = () => {
	const dispatch = useAppDispatch()
	const { zoomToStation } = useAppSelector((state) => state.selectedStation)
	const { isGeolocationLoaded, myCoords } = useAppSelector((state) => state.geolocation)
	const { isFollowingMyLocation } = useAppSelector((state) => state.stationsMap)
	const { stationsToDisplay } = useAppSelector((state) => state.stations)
	const { isAnyStationSelected, selectedStation } = useStationSelection()

	const map = useRef<Map | null>(null)
	const [mapCenter, setMapCenter] = useState<Coords | undefined>()
	const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout | undefined>()

	// set initial map center
	useEffect(() => {
		if (isAnyStationSelected || !isGeolocationLoaded || mapCenter)
			return

		const coordsToPan = myCoords ?? warsawPkinCoords
		goTo(map.current, coordsToPan)
		setMapCenter(coordsToPan)
	}, [
		map.current,
		isGeolocationLoaded,
		!!myCoords,
		mapCenter,
		isAnyStationSelected,
	])

	// follow my location
	useEffect(() => {
		if (isFollowingMyLocation && myCoords)
			goTo(map.current, myCoords)
	}, [isFollowingMyLocation, myCoords])

	// refresh stations on each map position change
	useEffect(() => {
		if (!mapCenter)
			return

		refreshTimeout && clearTimeout(refreshTimeout)
		setRefreshTimeout(
			setTimeout(() => {
				dispatch(updateStationsInCoords(mapCenter))
			}, 800)
		)
	}, [mapCenter])

	// fly to selected station
	useEffect(() => {
		if (selectedStation) {
			setMapCenter({ ...selectedStation.coords })
			const zoom = zoomToStation ? 18 : undefined
			const duration = zoomToStation ? 1.5 : 0.5

			map.current?.flyTo({
				lat: selectedStation.coords.latitude,
				lng: selectedStation.coords.longitude,
			}, zoom, { duration })
		}
	}, [selectedStation, zoomToStation])

	// set map center on each position change
	useEffect(() => {
		map.current?.addEventListener({
			zoomstart() {
				dispatch(unfollowMyLocation())
			},
			dragstart() {
				dispatch(unfollowMyLocation())
			},
			moveend() {
				setMapCenter({
					latitude: map.current?.getCenter().lat ?? 0,
					longitude: map.current?.getCenter().lng ?? 0,
				})
			},
		})

	}, [map.current])

	return (
		<React.Fragment>
			<MapContainer
				ref={map}
				className='map'
				center={{
					lat: warsawPkinCoords.latitude,
					lng: warsawPkinCoords.longitude,
				}}
				zoom={14}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>

				<StationMapStationsCluster stations={stationsToDisplay} />
				<MyCoordsMarker />

				<CurrentSearchPointMarker />

				{isMobile && (
					<div className='leaflet-control leaflet-bar mobile-map-btn bottom-left'>
						<StationsFiltering />
					</div>
				)}
				<div className='leaflet-control leaflet-bar mobile-map-btn top-right'>
					<FollowMyLocation />
				</div>
			</MapContainer>
		</React.Fragment>
	)
}

export default StationsMap
