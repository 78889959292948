import React, { useCallback, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { RootState } from '../../../store'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { filterRadiusChanged, refreshStationsToDisplay } from '../../stationsSlice'

const minRadius = 5
const maxRadius = 50

const RadiusFilter = (): JSX.Element => {
	const dispatch = useAppDispatch()
	const { radius } = useAppSelector((state: RootState) => state.stations.filters)
	const [tempRadius, setTempRadius] = useState<number>(radius)

	useEffect(() => {
		setTempRadius(radius)
	}, [radius])

	const applyRadius = useCallback(() => {
		dispatch(filterRadiusChanged(tempRadius))
		dispatch(refreshStationsToDisplay())
	}, [tempRadius])

	return (
		<Form.Group className='radius'>
			<Form.Label>Zasięg ({tempRadius} km)</Form.Label>
			<div className='slider'>
				<span>{minRadius} km</span>
				<Form.Range
					min={minRadius}
					max={maxRadius}
					step={5}
					value={tempRadius}
					onChange={(e) => setTempRadius(Number(e.target.value))}
					onTouchEnd={applyRadius}
					onMouseUp={applyRadius}
				/>
				<span className='max'>{maxRadius} km</span>
			</div>
		</Form.Group>
	)
}

export default RadiusFilter
