import React, { useEffect } from 'react'
import { useGeolocated } from 'react-geolocated'
import { isMobile } from 'react-device-detect'
import { useAppDispatch } from '../hooks'
import { geolocationSucceed, geolocationLoadingFailed, geolocationPermissionDenied, myCoordsChanged } from './geolocationSlice'
import useStationSelection from '../stations/selected-station/hooks/useStationSelection'

const geolocationPermissionStatus = async () => {
	if (navigator.geolocation) {
		const result = await navigator.permissions.query({ name: 'geolocation' })
		return result.state
	}
}

export const GeolocationProvider = (props: {children: JSX.Element}) => {
	const dispatch = useAppDispatch()
	const { isAnyStationSelected } = useStationSelection()

	const result = useGeolocated({
		watchPosition: true,
		userDecisionTimeout: 30000,
		suppressLocationOnMount: true,
		onSuccess: (position) => {
			dispatch(geolocationSucceed())
			dispatch(myCoordsChanged({
				latitude: position.coords.latitude,
				longitude: position.coords.longitude,
			}))
		},
		onError: (e) => {
			if (e?.code === e?.PERMISSION_DENIED)
				dispatch(geolocationPermissionDenied())
			else
				dispatch(geolocationLoadingFailed())
		},
		isOptimisticGeolocationEnabled: false,
	})

	useEffect(() => {
		geolocationPermissionStatus()
			.then((status) => {
				if (status === 'granted' || !isAnyStationSelected || !isMobile)
					result.getPosition()
			})
			.catch()
	}, [
		result.isGeolocationEnabled,
		isAnyStationSelected,
		isMobile,
	])

	return (
		<React.Fragment>
			{props.children}
		</React.Fragment>
	)
}
