import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Station } from '../models'
import { Marker, Popup } from 'react-leaflet'
import L from 'leaflet'
import { Container } from 'react-bootstrap'
import useStationProperties from '../hooks/useStationProperties'
import FuelPrices from '../station-details/FuelPrices'
import { isMobile } from 'react-device-detect'
import { isOverlapping } from './CurrentSearchPointMarker'
import { useAppSelector } from '../../hooks'
import useStationSelection from '../selected-station/hooks/useStationSelection'
import Distance from '../station-details/Distance'

type Props = {
	station: Station,
	onSelect: (stationId: string) => void,
}

const StationMapMarker = ({ onSelect, station }: Props) => {
	const { myCoords } = useAppSelector((state) => state.geolocation)
	const { isAnyStationSelected } = useStationSelection()
	const { areYouAround, lastUpdateText } = useStationProperties(station)

	const markerRef = useRef<L.Marker>(null)
	const [markerRect, setMarkerRect] = useState<DOMRect | undefined>()

	useEffect(() => {
		const interval = setInterval(() => {
			const currentMarkerRect = markerRef.current?.getElement()?.getBoundingClientRect()
			if (!currentMarkerRect)
				return

			if (
				markerRect?.bottom !== currentMarkerRect.bottom
				|| markerRect?.top !== currentMarkerRect.top
				|| markerRect?.left !== currentMarkerRect.left
				|| markerRect?.right !== currentMarkerRect.right
			)
				setMarkerRect(currentMarkerRect)

		}, 200)

		return () => {
			clearInterval(interval)
		}
	}, [markerRef, markerRect])

	useEffect(() => {
		if (isMobile && isAnyStationSelected) {
			markerRef.current?.closePopup()
			return
		}

		if (isOverlapping(markerRect))
			markerRef.current?.openPopup()
		else
			markerRef.current?.closePopup()
	}, [markerRect, isAnyStationSelected])

	const markerMemo = useMemo(() => (
		<Marker
			ref={markerRef}
			position={{
				lat: station.coords.latitude,
				lng: station.coords.longitude,
			}}
			eventHandlers={{
				click() { onSelect(station.id) },
				mouseover() { markerRef.current?.openPopup() },
				mouseout() { markerRef.current?.closePopup() },
			}}
		>
			<Popup
				closeButton={false}
				autoPan={false}
				closeOnClick={false}
			>
				<Container className='station-details p-0' onClick={() => isMobile && onSelect(station.id)}>
					{isMobile && <i>Kliknij, aby zobaczyć więcej</i>}
					<div className='title'>
						<p className='name mt-2'>{station.name}</p>
						<Distance
							areYouAround={areYouAround}
							stationCoords={station.coords}
							myCoords={myCoords}
						/>
					</div>
					<p className='last-update'>{lastUpdateText}</p>
					<FuelPrices prices={station.prices} />
				</Container>
			</Popup>
		</Marker>
	), [station])

	return markerMemo
}

export default StationMapMarker
