import React, { useCallback } from 'react'
import Button from 'react-bootstrap/Button'
import './StationDetails.scss'
import { Station } from '../models'
import getStationAddress from '../../utils/getStationAddress'
import StationNavigateButton from './NavigateToButton'
import { isMobile } from 'react-device-detect'
import ProposePricesButton from './propose-prices/ProposePricesButton'
import { PinMap } from 'react-bootstrap-icons'
import ShareStationButton from './share-station/ShareStationButton'
import { Col, Container, Row } from 'react-bootstrap'
import useStationProperties from '../hooks/useStationProperties'
import FuelPrices from './FuelPrices'
import { useAppSelector } from '../../hooks'
import useStationSelection from '../selected-station/hooks/useStationSelection'
import Distance from './Distance'

type Props = {
	station: Station,
	showOnMapVisible?: boolean,
	onShowOnMap?: () => void,
	withPricesProposal?: boolean,
}

const StationDetails = ({ station, onShowOnMap, showOnMapVisible, withPricesProposal }: Props): JSX.Element => {
	const { myCoords } = useAppSelector((state) => state.geolocation)
	const { selectStationWithZoom } = useStationSelection()
	const { lastUpdateText, areYouAround } = useStationProperties(station)
	const { isStationSelected } = useStationSelection()

	const isSelected = isStationSelected(station.id)

	const onShowStationOnMap = useCallback(() => {
		if (onShowOnMap)
			onShowOnMap()

		if (!isSelected)
			selectStationWithZoom(station.id)
	}, [station.id, isSelected])

	return (
		<Container className='station-details'>
			<ShareStationButton station={station} />
			<Row>
				<div className='title'>
					<p className='name'>{station.name}</p>
					<i className='address'>{getStationAddress(station.address)}</i>
					<Distance
						areYouAround={areYouAround}
						stationCoords={station.coords}
						myCoords={myCoords}
					/>
				</div>
			</Row>

			<p className='last-update'>{lastUpdateText}</p>

			<FuelPrices prices={station.prices} />

			<Row className='action-buttons'>
				{((isMobile && showOnMapVisible) ?? true) && (
					<Col>
						<Button onClick={onShowStationOnMap}>
							<PinMap />
							<span>Lokalizacja</span>
						</Button>
					</Col>
				)}
				<Col>
					<StationNavigateButton station={station} />
				</Col>
				{(withPricesProposal ?? true)
					&&	(
						<Col>
							<ProposePricesButton
								stationPrices={station.prices}
								isTooFar={!areYouAround} />
						</Col>
					)
				}
			</Row>
		</Container>
	)
}

export default StationDetails
