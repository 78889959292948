import React, { useEffect, useState } from 'react'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import StationMapMarker from './StationMapMarker'
import useStationSelection from '../selected-station/hooks/useStationSelection'

const StationMapStationsCluster = ({ stations }) => {
	const { selectStation } = useStationSelection()
	const [stationMarkersDict, setStationMarkers] = useState({})

	useEffect(() => {
		setStationMarkers((prevStationMarkers) => {
			const newDict = {}

			stations.forEach((station) => {
				if (stationMarkersDict[station.id])
					newDict[station.id] = prevStationMarkers[station.id]
				else {
					newDict[station.id] = (
						<StationMapMarker
							key={station.id}
							station={station}
							onSelect={selectStation}
						/>
					)
				}
			})

			return newDict
		})
	}, [stations])

	return (
		<MarkerClusterGroup
			removeOutsideVisibleBounds={false}>
			{Object.keys(stationMarkersDict)
				.map((stationId) =>
					stationMarkersDict[stationId])}
		</MarkerClusterGroup>
	)
}

export default StationMapStationsCluster
